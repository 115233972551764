@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-icon {
  display: flex;
  justify-content: center;
  margin: 0 auto 60px;
  animation: rotate 2s linear infinite;
  transform: translate3d(0, 0, 0);
  width: 50px;
  height: 50px;
}
